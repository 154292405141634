import styled from 'styled-components';
import { StyledValuesModule } from '../Values/Values.style';
import { pxtorem } from '../../utils/tools';
import { StyledValuePropGrid } from '../ValuePropGrid/ValuePropGrid.style';
import { Section } from '../../styles/Section.styled';

// [181, 215, 151] Shape container heights
export const StyledJoinPage = styled.div`
  .pull-quote-blob__cta {
    min-width: 170px;

    @media (max-width: 767px) {
      width: auto;
    }
  }

  ${StyledValuesModule} {
    .values__mediaShape {
      svg {
        width: auto;
        height: 100%;
        max-height: 151px;
      }
    }
  }

  ${StyledValuePropGrid} {
    .grid {
      max-width: 734px;
    }
  }
`;
