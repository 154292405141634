import { useMediaQuery } from '@mantine/hooks';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useCartContext } from '~/components/CartContext/CartContext';
import { StyledJoinHero } from '~/components/JoinHero/JoinHero.styled';
import { ContentCard, ContentfulPageSection } from '~/types/types';
import { Cta } from '../Generic/Cta/Cta';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';

export const JoinHero: React.FC<JoinHeroProps> = ({ data }) => {
  const { enrollAmbassador } = useCartContext();
  const showMobileHero = useMediaQuery('(max-width: 768px)');
  const contentCards = data?.media as ContentCard[];
  const cta = data?.cta;
  const [{ value: ctaText }] = JSON.parse(cta?.raw).content?.[0]?.content;
  const images = contentCards.slice(0, 4);
  const valueProps = contentCards.slice(4);
  const [leftImages, rightImages] = [images.slice(0, 2), images.slice(2)];

  return (
    <StyledJoinHero>
      <div className="grid">
        {!showMobileHero && (
          <div className="img-col">
            {leftImages?.map(({ media }) => {
              return (
                <GatsbyImage
                  key={media.id}
                  objectFit="cover"
                  image={media.gatsbyImageData}
                  alt={''}
                />
              );
            })}
          </div>
        )}
        {showMobileHero && images?.length && (
          <div className="img-col--mobile">
            {images.map(({ media }) => (
              <GatsbyImage
                key={media.id}
                objectFit="cover"
                image={{
                  ...media.gatsbyImageData,
                }}
                alt={''}
              />
            ))}
          </div>
        )}
        <div className="content">
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html,
              }}
            />
            <Cta className="join-btn" onClick={enrollAmbassador}>
              {ctaText}
            </Cta>
          </div>

          <div className="value-props">
            {(valueProps || []).map((valueProp, i) => (
              <ShowOnScroll key={valueProp.id} delay={i * 100}>
                <div className="img-wrapper">
                  <img src={valueProp.media?.file.url} />
                </div>
                <p>{valueProp.label}</p>
              </ShowOnScroll>
            ))}
          </div>
        </div>

        {!showMobileHero && (
          <div className="img-col">
            {rightImages?.map(({ media }) => {
              return (
                <GatsbyImage
                  key={media.id}
                  image={media.gatsbyImageData}
                  alt={''}
                />
              );
            })}
          </div>
        )}
      </div>
    </StyledJoinHero>
  );
};

interface JoinHeroProps {
  data: ContentfulPageSection;
}
