import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledBasicHero = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  .bg-img {
    width: 100%;
    object-fit: cover;
    height: 697px;

    @media (max-width: 768px) {
      height: 400px;
    }
  }

  .basic-hero--content {
    position: absolute;
    top: 50%;
    transform: translateY(-75%);
    color: var(--white);
    padding-inline: var(--gutter);

    h1 {
      margin: 0;
      font-size: clamp(${pxtorem(60)}, 6vw, ${pxtorem(90)});
      text-align: center;

      em {
        font-size: clamp(${pxtorem(52)}, 5.06666667vw, ${pxtorem(76)});
      }
    }
  }
`;
