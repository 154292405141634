import { graphql } from 'gatsby';
import React from 'react';
import { JoinPage } from '../components/JoinPage/JoinPage';
import Layout from '../components/Layout/Layout';
import Metadata from '../components/Metadata';
import { ContentfulPageSection, GatsbyImageData } from '../types/types';

const Join: React.FC<JoinProps> = ({ data }) => {
  return (
    <Layout floatingHeader>
      <Metadata title={data.page?.name} />
      <JoinPage hero={data.page.hero} sections={data.page.sections} />
    </Layout>
  );
};

interface JoinProps {
  data: JoinPageData;
}

export interface JoinPageData {
  page: {
    name: string;
    hero: ContentfulPageSection;
    sections: ContentfulPageSection[];
  };
}

export const query = graphql`
  query JoinPageQuery($locale: String) {
    page: contentfulPage(slug: { eq: "join" }, node_locale: { eq: $locale }) {
      name
      hero {
        ...ContentSectionFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`;
export default Join;
