import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import React from 'react';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledBasicHero } from './BasicHero.style';

type GatsbyImageData = GatsbyImageProps['image'];

export const BasicHero: React.FC<BasicHeroProps> = ({ image, content }) => {
  return (
    <StyledBasicHero>
      <GatsbyImage
        image={image}
        alt="About Hero"
        className="bg-img"
        objectFit="cover"
      />
      <div className="basic-hero--content">
        <ShowOnScroll
          dangerouslySetInnerHTML={{
            __html: content.childMarkdownRemark.html,
          }}
        />
      </div>
    </StyledBasicHero>
  );
};

interface BasicHeroProps {
  content: MarkdownData;
  image: GatsbyImageData;
}
