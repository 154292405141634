import styled from 'styled-components';
import { desktopHover, pxtorem } from '~/utils/tools';

export const StyledJoinHero = styled.section`
  --imageWidth: 350px;
  --contentGap: ${pxtorem(103)};

  color: var(--white);
  background-color: var(--green);

  .grid {
    max-width: 1980px;
    display: grid;
    margin-inline: auto;
    grid-template-columns: var(--imageWidth) 1fr var(--imageWidth);
  }

  .img-col {
    width: 100%;

    &--mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--contentGap);
    justify-content: flex-end;
    text-align: center;
    min-height: 383px;
    padding-bottom: ${pxtorem(48)};
    padding-inline: ${pxtorem(24)};

    position: relative;

    h1 {
      max-width: 379px;
      text-align: center;
      line-height: 1.05;
      margin: 0;
      margin-bottom: ${pxtorem(20)};
      font-size: clamp(${pxtorem(60)}, 6vw, ${pxtorem(90)});
      text-align: center;
      em {
        font-size: clamp(${pxtorem(52)}, 5.06666667vw, ${pxtorem(76)});
      }
    }

    .join-btn {
      border-color: var(--white);
      width: 100%;
      max-width: 222px;
      font: 500 ${pxtorem(20)} / 1.2 var(--primaryFont);
      padding: ${pxtorem(18)} ${pxtorem(26)};

      ${desktopHover(`
        background-color: var(--white);
        color: var(--green);
      `)}
    }

    .value-props {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: ${pxtorem(24)};
      max-width: 528px;

      & .img-wrapper {
        display: inline-block;
        max-width: ${pxtorem(34)};
      }

      p {
        font: 500 ${pxtorem(16)} / 1.1125 var(--primaryFont);
        margin: 0;
        margin-top: 12px;
      }
    }
  }

  @media (max-width: 1280px) {
    --imageWidth: 25vw;
    --contentGap: ${pxtorem(80)};
  }

  @media (max-width: 1080px) {
    --contentGap: 15%;

    .content {
      padding-bottom: ${pxtorem(24)};
      padding-inline: ${pxtorem(16)};

      .value-props {
        gap: 12px;
        p {
          font-size: 12px;
        }

        .img-wrapper {
          max-width: ${pxtorem(26)};
        }
      }

      .join-btn {
        font-size: 16px;
        padding: 14px ${pxtorem(18)};
        width: auto;
      }
    }
  }

  @media (max-width: 768px) {
    --contentGap: ${pxtorem(60)};
    .grid {
      grid-template-columns: 1fr;
    }

    .content {
      justify-content: flex-start;
      padding-top: ${pxtorem(44)};

      .value-props {
        &:nth-child(1) {
          margin-right: 13px;
        }

        &:nth-child(2) {
          margin-left: 8px;
        }
        p {
          font: 500 12px / 1.1666667 var(--primaryFont);
        }
      }
    }
  }
`;
