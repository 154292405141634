import React, { useEffect } from 'react';

import { ContentfulPageSection } from '~/types/types';
import { PageSection, ComponentMapExtraData } from '../PageSection/PageSection';

import { BasicHero } from '../BasicHero/BasicHero';

import { StyledJoinPage } from './JoinPage.style';
import { useCartContext } from '../CartContext/CartContext';
import { PullQuoteBlobProps } from '../PullQuoteBlob/PullQuoteBlob';
import { ValuePropGridProps } from '../ValuePropGrid/ValuePropGrid';
import { JoinHero } from '~/components/JoinHero/JoinHero';
import { AMBASSADOR_ENROLLMENT_MAXDATE } from '~/types/constants';

type JoinPageSectionSlugs =
  | 'join-page-pull-quote'
  | 'join-page-faqs'
  | 'why-become-an-ambassador'
  | 'join-pillars'
  | 'join-page-testimonials'
  | 'video-carousel'
  | 'is-commons-right-for-you'
  | 'join-page-image-banner'
  | 'guided-protocols';

const now = new Date();

export const JoinPage: React.FC<JoinPageProps> = ({ hero, sections }) => {
  const {enrollAmbassador} = useCartContext();
  const joinPageSectionsDefaultData: ComponentMapExtraData = {
    values: {
      sectionProps: { bg: 'gray' },
    },
    faq: {
      sectionProps: { bg: 'gray' },
      wrapperProps: {
        width: 'normal'
      }
    },
    pillars: {
      sectionProps: { bg: 'gray', gutter: true },
      wrapperProps: { bg: 'white', width: 'wide' },
    },
    'pull-quote-blob': {
      componentProps: {
        onCtaClick: enrollAmbassador
      } as PullQuoteBlobProps
    },
    'value-prop-grid': {
      componentProps: {
        maxColumns: 2,
        onCtaClick: enrollAmbassador
      } as ValuePropGridProps,
      sectionProps: { bg: 'gray' },
    },
    'carousel-auto': {
      sectionProps: { bg: 'gray' },
    },
    'featured-protocols': {
      sectionProps: { bg: 'green', gutter: true },
      wrapperProps: { bg: 'white' },
    },
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (now.getTime() > AMBASSADOR_ENROLLMENT_MAXDATE.getTime()) {
        window.location.href = "/"
      }
    }
  }, [])

  return (
    <StyledJoinPage style={now.getTime() > AMBASSADOR_ENROLLMENT_MAXDATE.getTime() ? {display: 'none'} : null}>
      {hero.sectionType?.slug === "join-page-hero" && <JoinHero data={hero} />} 
      {!hero.sectionType && <BasicHero
        image={hero.media[0].media.gatsbyImageData}
        content={hero.description}
      />}
      {sections &&
        sections.map((section, idx) => {
          if (!section?.sectionType) {
            return null;
          }

          const componentData =
            joinPageSectionsDefaultData[section.sectionType.slug] || {};

          return (
            <PageSection
              key={`${section.slug}_${idx}`}
              section={section}
              {...componentData}
            />
          );
        })}
    </StyledJoinPage>
  );
};

interface JoinPageProps {
  hero: ContentfulPageSection;
  sections: ContentfulPageSection[];
}
